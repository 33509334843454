<template>
  <div class="story_top_container">
    <div class="story_left">
      <SfImage
        :src="image1"
        alt="Image"
        loading="eager"
        width="140"
        height="140"
      />
    </div>
    <div
      class="story_right"
      :style="style"
    >
      <h2 class="heading">
        {{ heading }} <br>
        {{ heading2 }}
      </h2>
      <div class="story_right-detail">
        <span>{{ content }}</span>
      </div>
      <div
        v-if="!!button || !!link"
        class="story_right-button"
      >
        <nuxt-link :to="localePath(link)">
          <span>{{ button }}</span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
<script>
import { SfImage } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'StoryBanner',
  components: {
    SfImage,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    heading2: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    image1: {
      type: String,
      default: '',
    },
    image2: {
      type: String,
      default: '',
    },
    bgcolor: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    button: {
      type: String,
      default: '',
    },
  },
  setup() {},
  computed: {
    style() {
      return `background-color: ${this.bgcolor}`;
    },
  },
});
</script>

<style lang="scss">
.story_top_container {
  display: flex;
  padding: 0 24px 80px;
  max-width: 1414px;
  margin: 0 auto;

  .sf-image--wrapper,
  .sf-image {
    display: inline;
  }
  .story_left {
    max-width: 484px;
    .sf-image--wrapper{
      .sf-image{
        width:100%;
        height: 100%;
      }
    }

    .sf-image {
      width: 100%;
      height: auto;
    }
  }

  .story_right {
    width: calc(100% - 484px);
    padding: 90px 74px;

    .heading {
      font-size: 46px;
      line-height: 55px;
      color: #222a24;
      margin: 0 0 12px;
    }
    .story_right-detail {
      margin-bottom: 60px;
      span {
        color: #222a24;
        font-size: 14px;
        line-height: 26px;
      }
    }
    .story_right-button {
      display: flex;

      @include for-mobile {
        justify-content: center;
      }

      a {
        background: #fff;
        min-width: 300px;
        text-align: center;
        transition-duration: 0.4s;

        &:hover,
        &:active,
        &:focus {
          background: #333;
        }

        span {
          color: #222a24;
          display: block;
          font-family: 'Spartan';
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 1px;
          line-height: 12px;
          padding: 28px 20px 24px;
          text-align: center;
          text-transform: uppercase;

          &:hover,
          &:active,
          &:focus {
            color: #fff;
          }
        }

        @media (max-width: 400px) {
          min-width: unset;
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 1251px) {
  .story_top_container {
    .story_right {
      padding: 50px 35px;
    }
  }
}
@media (max-width: 1080px) {
  .story_top_container {
    .story_left {
      max-width: 50%;
    }
    .story_right {
      width: 50%;
    }
    .sf-image--wrapper {
      .sf-image {
        width: 100%;
        height: 100%
      }
    }

  }
}
@media (max-width: 768px) {
  .story_top_container {
    flex-direction: column;
    .story_right,
    .story_left {
      max-width: 100%;
      width: 100%;
      .sf-image--wrapper,
      .sf-image{
        display: block;
      }
    }
    .story_right {
      .heading {
        font-size: 32px;
        line-height: 36px;
        margin: 0 0 5px;
      }
      span {
        font-size: 12px;
        line-height: 20px;
      }
      .story_right-button {
        justify-content: center;
      }
    }
  }
}
</style>
